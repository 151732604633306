import React from "react"
import Layout from "../layout"
import parse from 'html-react-parser';

import "./../layout.scss"
import './styles.scss'

function StrategyCallContent({ lang, location }) {

    const dictionaryList = {
        en: {
            title: 'Your Free 45 Minute<br>Strategy Call'
        },
        de: {
            title: 'Dein kostenloser 45-Minunten<br>Strategy Call'
        }
    };

    let content;
    lang === "de" ? (content = dictionaryList.de) : (content = dictionaryList.en)

    return (
        <div>
            <Layout lang={lang} showLanguageSelector={false} location={location}>
                <section className="section content-section">
                    <div className="container">
                        <div className="columns">
                            <div class="column is-12">
                                <div class="column" className="">
                                    <h1 className="t-bold">{parse(content.title)}</h1>
                                    <form name="contact" method="POST" data-netlify="true" action="/" netlify-honeypot="bot-field">
                                        <p hidden aria-hidden="true">
                                            <label>
                                                Don’t fill this out if you're human:
                                                <input name="bot-field" />
                                            </label>
                                        </p>
                                        <p>
                                            <label>Your Name: <input type="text" name="name" /></label>
                                        </p>
                                        <p>
                                            <label>Your Email: <input type="email" name="email" required /></label>
                                        </p>
                                        <p>
                                            <label>Your Role: <select name="role[]" multiple>
                                                <option value="leader">Leader</option>
                                                <option value="follower">Follower</option>
                                            </select></label>
                                        </p>
                                        <p>
                                            <label>Message: <textarea name="message"></textarea></label>
                                        </p>
                                        <p>
                                            <button type="submit">Send</button>
                                        </p>
                                        <input type="hidden" name="form-name" value="contact" />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </div>
    )
}

export default StrategyCallContent;